import { Module } from "vuex";

import { api } from "@/util/axios";
import { NearbySearchResult, PlaceSearchFromTextResult } from "@/types";

type NearbySearchState = {
  nearbySearchResult: NearbySearchResult | undefined;
  placeSearchFromTextResult: PlaceSearchFromTextResult | undefined;
};

const role: Module<NearbySearchState, unknown> = {
  namespaced: true,
  state: () => ({
    nearbySearchResult: undefined,
    placeSearchFromTextResult: undefined,
  }),
  getters: {
    nearbySearchResult: (state) => state.nearbySearchResult,
    placeSearchFromTextResult: (state) => state.placeSearchFromTextResult,
  },
  mutations: {
    SET_NEARBY_SEARCH_RESULT: (state, result) => {
      state.nearbySearchResult = result;
    },
    SET_FROM_TEXT_SEARCH_RESULT: (state, result) => {
      state.placeSearchFromTextResult = result;
    },
  },
  actions: {
    async fetchNearbySearchResult(context, params = "") {
      return await api
        .get(`/v1/place/nearby-search${params}`)
        .then((response) => {
          context.commit("SET_NEARBY_SEARCH_RESULT", response.data);
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async searchFromText(context, params = "") {
      return await api
        .get(`/v1/place/place-from-text${params}`)
        .then((response) => {
          context.commit("SET_FROM_TEXT_SEARCH_RESULT", response.data);
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default role;
