import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { JobTitle, Page } from "@/types";

type JobTitleState = {
  jobTitlePage: Page<JobTitle>;
};

const jobTitle: Module<JobTitleState, unknown> = {
  namespaced: true,
  state: () => ({
    jobTitlePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getJobTitle: (state) => (jobTitleId: string) =>
      state.jobTitlePage.docs.find((c) => c._id === jobTitleId),
    jobTitlePage: (state) => state.jobTitlePage,
  },
  mutations: {
    ADD_JOB_TITLE: (state, _jobTitle) => {
      let idx = -1;
      state.jobTitlePage.docs.map((a, i) => {
        if (a._id === _jobTitle._id) idx = i;
      });
      if (idx === -1) state.jobTitlePage.docs.push(_jobTitle);
      else Vue.set(state.jobTitlePage.docs, idx, _jobTitle);
    },
    SET_JOB_TITLE_PAGE: (state, list) => {
      state.jobTitlePage = list;
    },
    REMOVE_JOB_TITLE(state, role) {
      let idx = -1;
      state.jobTitlePage.docs.map((r, i) => {
        if (r._id === role._id) idx = i;
      });
      if (idx > -1) state.jobTitlePage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchJobTitle(context, params = "") {
      api
        .get(`/v1/job-title${params}`)
        .then((response) => {
          context.commit("ADD_JOB_TITLE", response.data.jobTitle);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchJobTitleList(context, params = "") {
      return await api
        .get(`/v1/job-title${params}`)
        .then((response) => {
          context.commit("SET_JOB_TITLE_PAGE", response.data.jobTitlePage);
          return response.data.jobTitlePage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createJobTitle(context, payload) {
      return await api
        .post(`/v1/job-title`, payload)
        .then((response) => {
          context.commit("ADD_JOB_TITLE", response.data.jobTitle);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Job Title created",
            },
            { root: true }
          );
          return response.data.jobTitle;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateJobTitle(context, data: { id: string; jobTitle: JobTitle }) {
      return await api
        .put(`/v1/job-title/${data.id}`, data.jobTitle)
        .then((response) => {
          context.commit("ADD_JOB_TITLE", response.data.jobTitle);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Job Title updated",
            },
            { root: true }
          );
          return response.data.jobTitle;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteJobTitle(context, params) {
      return await api
        .delete(`/v1/job-title/${params}`, {
          headers: { "vendor-module": "employees.Job Title" },
        })
        .then((response) => {
          context.commit("REMOVE_JOB_TITLE", response.data.jobTitle);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Job Title deleted",
            },
            { root: true }
          );
          return response.data.jobTitle;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default jobTitle;
