


























































































































































































































































































































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import serviceStoreModule from "@/store/modules/service";
import serviceCategoryStoreModule from "@/store/modules/serviceCategory";
import locationStoreModule from "@/store/modules/location";
import {
  AdvancedPricing,
  Business,
  Page,
  Role,
  ServiceCategory,
  _Location,
} from "@/types";
import AdvancedPricingOptionForm from "@/components/service/AdvancedPricingOptionForm.vue";
import ServiceCategoryForm from "@/components/service/ServiceCategoryForm.vue";
import { durations } from "@/util/constants";
import { removeUndefinedProps } from "@/util/helpers";

const { mapActions: serviceActions } = createNamespacedHelpers("SERVICE_");
const {
  mapActions: serviceCategoryActions,
  mapGetters: serviceCategoryGetters,
} = createNamespacedHelpers("SERVICE_CATEGORY");

const { mapActions: locationActions, mapGetters: locationGetters } =
  createNamespacedHelpers("NEARBY_SEARCH");

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField0: HTMLInputElement;
        imageField1: HTMLInputElement;
        imageField2: HTMLInputElement;
        imageField3: HTMLInputElement;
      };
      locationPage: Page<_Location>;
      serviceCategoryPage: Page<ServiceCategory>;
    }
  >
).extend<any, any, any, any>({
  components: { AdvancedPricingOptionForm, ServiceCategoryForm },
  name: "SingleServiceForm",
  data: () => ({
    onlineBooking: true,
    virtualBooking: false,
    images: [] as File[],
    name: "",
    categoryIds: "",
    availableFor: "",
    description: "",
    nameRules: [(v: string) => !!v || "Name field is required"],
    categoryRules: [(v: string) => !!v || "Category field is required"],
    maxSimultaneousClients: undefined,
    maxSimultaneousClientsRules: [
      (v: string) => !!v || "Max Simultaneous Clients is required",
    ],
    selectedLocations: [] as _Location[],
    prices: [
      {
        price: 500,
        durationInMinutes: 30,
        name: "Offer",
      },
    ],
    location: "",
    advancedPricing: [] as AdvancedPricing[],
    advancedPricingDialog: false,
    durations,
    durationRules: [(v: string) => !!v || "Duration field is required"],
    advancedPriced: false,
    addCategoryDialog: false,
  }),
  watch: {
    role() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}`;
        this.fetchLocationList(params + "&limit=1000");
        this.fetchServiceCategoryList(params);
      }
    },
  },
  created() {
    if (this.role) {
      const bid = (this.role.business as Business)._id;
      let params = `?businessId=${bid}`;
      this.fetchLocationList(params + "&limit=1000");
      this.fetchServiceCategoryList(params);
    }
  },
  computed: {
    ...serviceCategoryGetters(["serviceCategoryPage"]),
    ...locationGetters(["locationPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    class_(): boolean {
      return this.$route.query.class === "true";
    },
    allLocations: {
      get() {
        return this.locationPage.docs.every(
          (item: _Location) =>
            !!this.selectedLocations.map((loc) => loc._id).includes(item._id)
        );
      },
      set(val) {
        if (val) {
          this.selectedLocations = this.locationPage.docs;
        } else this.selectedLocations = [];
      },
    },
  },
  methods: {
    ...serviceActions(["createService", "uploadServiceImage"]),
    ...serviceCategoryActions(["fetchServiceCategoryList"]),
    ...locationActions(["fetchLocationList"]),
    validateService() {
      const valid = (
        this.$refs.serviceForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      this.createService(
        removeUndefinedProps({
          name: this.name,
          categoryIds: this.categoryIds,
          businessId: (this.role.business as Business)._id,
          // durationInMinutes: this.duration,
          locationAvailability: "single",
          description: this.description,
          interactionType: this.virtualBooking ? "virtual" : "in-person",
          option: "class",
          // travelSurchargePerKilometer: 100,
          maxSimultaneousClients: this.maxSimultaneousClients,
          location: this.allLocations ? "All" : this.location,
          locations: this.selectedLocations.map((item) => item._id),
          advancedPricing:
            this.advancedPricing.length && this.advancedPriced
              ? this.advancedPricing.map((item) => ({
                  currency: item.currency,
                  name: item.name,
                  price: item.price,
                  locations: item.locations,
                  durationInMinutes: item.durationInMinutes,
                  deposit: item.deposit,
                  staffPricing: item.staffPricing,
                }))
              : this.prices.map((p) => ({
                  name: p.name,
                  price: p.price,
                  durationInMinutes: p.durationInMinutes,
                })),
          availableFor: this.availableFor.toLowerCase(),
        })
      ).then((service) => {
        if (service) {
          if (this.images.length) {
            const formData = new FormData();
            this.images.map((i) => {
              formData.append("files", i);
            });
            this.uploadServiceImage({
              id: service._id,
              service: formData,
            }).then((s) => {
              if (s) this.$router.push("/services");
            });
          } else this.$router.push("/services");
        }
      });
    },
    selectLocation(l: _Location) {
      const indx = this.selectedLocations.findIndex(
        (item) => item._id === l._id
      );

      if (indx === -1) this.selectedLocations.push(l);
      else this.$delete(this.selectedLocations, indx);
    },
    upload(files: FileList) {
      Object.values(files).map((file) => this.images.push(file));
    },
    addPricingOption() {
      this.prices.push({
        price: 500,
        durationInMinutes: 30,
        name: "Offer",
      });
    },
    saveAdvancedPricing(advancedPricing: AdvancedPricing[]) {
      this.advancedPricing = advancedPricing;
      this.advancedPricingDialog = false;
      this.advancedPriced = true;
    },
    fetchCategories() {
      const bid = (this.role.business as Business)._id;
      let params = `?businessId=${bid}`;
      this.addCategoryDialog = false;

      this.fetchServiceCategoryList(params);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SERVICE_")) {
      this.$store.registerModule("SERVICE_", serviceStoreModule);
    }
    if (!this.$store.hasModule("SERVICE_CATEGORY")) {
      this.$store.registerModule(
        "SERVICE_CATEGORY",
        serviceCategoryStoreModule
      );
    }
    if (!this.$store.hasModule("NEARBY_SEARCH")) {
      this.$store.registerModule("NEARBY_SEARCH", locationStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SERVICE_");
    this.$store.unregisterModule("SERVICE_CATEGORY");
    this.$store.unregisterModule("NEARBY_SEARCH");
  },
});
