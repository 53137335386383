





















































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import locationStoreModule from "@/store/modules/location";
import nearbySearchStoreModule from "@/store/modules/placeSearch";
import { Business, _Location, Role, PlaceSearchFromTextResult } from "@/types";
import { Client } from "@googlemaps/google-maps-services-js";
const client = new Client({
  /*axiosInstance: axiosInstance*/
});

const { mapActions: locationActions } =
  createNamespacedHelpers("LOCATION_FORM");

const { mapActions: placeSearchActions } =
  createNamespacedHelpers("LOCATION_SEARCH");

const KEY = process.env.VUE_APP_GOOGLE_GEOCODING_API_KEY as string;

const getLatLon = async (place: string) => {
  return await client.geocode({
    params: {
      key: KEY,
      address: place,
    },
  });
};

export default Vue.extend({
  name: "LocationForm",
  props: {
    location: {
      type: Object as PropType<_Location>,
    },
  },
  data: () => ({
    nameRules: [(v: string) => !!v || "Name is required"],
    name: "",
    showLocationDeleteDialog: false,
    latlng: "",
    searchedLocations: [] as string[],
    customLocation: "",
    isLoadingLocations: false,
    isDefault: false,
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...locationActions(["createLocation", "updateLocation"]),
    ...placeSearchActions(["searchFromText"]),
    async saveLocation() {
      const valid = (
        this.$refs.titleForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const res = await getLatLon(this.name);
      if (res.data.status === "ZERO_RESULTS") {
        this.$store.dispatch("setToast", {
          // title: "Location Invalid",
          type: "error",
          text: "Please pick a different location!",
        });
        return;
      }
      const latitude = res.data.results[0]?.geometry.location.lat;
      const longitude = res.data.results[0]?.geometry.location.lng;

      if (this.location && this.location._id) {
        this.updateLocation({
          id: this.location._id,
          location: {
            name: this.name,
            latitude,
            longitude,
            isDefault: this.isDefault,
          },
        }).then((location) => {
          if (location) {
            this.$emit("saved", true);
          }
        });
        return;
      }

      this.createLocation({
        name: this.name,
        businessId: (this.role.business as Business)._id,
        area: this.name,
        latitude,
        longitude,
        isDefault: this.isDefault,
      }).then((title) => {
        if (title) {
          this.$emit("saved", true);
        }
      });
    },
    resetForm() {
      (
        this.$refs.titleForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();

      this.name = "";
    },
    searchLocation(q: string) {
      this.customLocation = q;
      if (q) {
        this.isLoadingLocations = true;
        let params = `?text=${q}`;
        if (this.latlng) params = params + `&latlng=${this.latlng}`;
        this.searchFromText(params).then((res: PlaceSearchFromTextResult) => {
          this.isLoadingLocations = false;
          if (res) {
            this.searchedLocations = res.candidates.map(
              (c) => c.formatted_address
            );
          }
        });
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("LOCATION_SEARCH")) {
      this.$store.registerModule("LOCATION_SEARCH", nearbySearchStoreModule);
    }
    if (!this.$store.hasModule("LOCATION_FORM")) {
      this.$store.registerModule("LOCATION_FORM", locationStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("LOCATION_SEARCH");
    this.$store.unregisterModule("LOCATION_FORM");
  },
});
