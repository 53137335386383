






import ServiceStepper from "@/components/service/ServiceStepper.vue";
import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  components: { ServiceStepper },
  name: "ServiceForm",
});
