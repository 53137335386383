



































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import serviceStoreModule from "@/store/modules/service";
import serviceCategoryStoreModule from "@/store/modules/serviceCategory";
import servicePackageStoreModule from "@/store/modules/servicePackage";
import { Business, Role, Service } from "@/types";

const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICE_");
const { mapActions: servicePackageActions } =
  createNamespacedHelpers("SERVICE_PACKAGE");
const {
  mapActions: serviceCategoryActions,
  mapGetters: serviceCategoryGetters,
} = createNamespacedHelpers("SERVICE_CATEGORY");
const API_URL = process.env.VUE_APP_API_URL;
export default Vue.extend<any, any, any, any>({
  name: "ServicePackageForm",
  data: () => ({
    addServiceDialog: false,
    currency: "KES",
    apiUrl: API_URL,
    service: "",
    onlineBooking: true,
    images: [] as File[],
    name: "",
    categoryIds: "",
    availableFor: "",
    description: "",
    nameRules: [(v: string) => !!v || "Name field is required"],
    categoryRules: [(v: string) => !!v || "Category field is required"],
    services: [] as Service[],
    serviceCategoryId: "",
    serviceId: "",
    price: null,
    priceRules: [
      (v: number) => !!v || "Price field is required",
      (v: number) => v > 0 || "Price must be greater than 0",
    ],
  }),
  computed: {
    ...serviceGetters(["servicePage"]),
    ...serviceCategoryGetters(["serviceCategoryPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    role: "fetchServices",
  },
  created() {
    this.fetchServices();
  },
  methods: {
    ...serviceActions(["fetchServiceList"]),
    ...serviceCategoryActions(["fetchServiceCategoryList"]),
    ...servicePackageActions(["createServicePackage"]),
    validateForm() {
      const valid = (
        this.$refs.serviceForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      this.createServicePackage({
        name: this.name,
        serviceIds: this.services.map((s) => s._id),
        categoryIds: this.categoryIds,
        description: this.description,
        businessId: (this.role.business as Business)._id,
        availableFor: this.availableFor.toLowerCase(),
        price: this.price,
        currency: this.currency,
      }).then((_package) => {
        if (_package) this.$router.push({ path: "/services" });
      });
    },
    deleteService(service: Service) {
      let indx = -1;
      this.services.map((s, i) => {
        if (s._id === service._id) indx = i;
      });
      this.services.splice(indx, 1);
    },
    addService() {
      const service = this.servicePage.docs.find(
        (s: Service) => s._id === this.service
      );
      this.services.push(service);
      this.addServiceDialog = false;
      this.service = "";
    },
    fetchServices() {
      if ((this.role?.business as Business)._id) {
        const businessId = (this.role.business as Business)._id;
        const params = `?businessId=${businessId}&limit=10000`;
        this.fetchServiceList(params);
        this.fetchServiceCategoryList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SERVICE_")) {
      this.$store.registerModule("SERVICE_", serviceStoreModule);
    }
    if (!this.$store.hasModule("SERVICE_CATEGORY")) {
      this.$store.registerModule(
        "SERVICE_CATEGORY",
        serviceCategoryStoreModule
      );
    }
    if (!this.$store.hasModule("SERVICE_PACKAGE")) {
      this.$store.registerModule("SERVICE_PACKAGE", servicePackageStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SERVICE_");
    this.$store.unregisterModule("SERVICE_CATEGORY");
  },
});
